import styled from "styled-components";
import FormBackground from "../assets/form-background.jpg";

const nav_height = "60px";

const Navbar = styled.div`
  //background: linear-gradient(to top, #011229 0%, #032c4b 100%);
  background-color: #0d1b30;
  height: ${nav_height};
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  position: absolute;
  margin-bottom: ${nav_height};
  z-index: 1;
  div {
    text-decoration: none;
    color: #fff;
    font-size: 3rem;
    margin-left: 15px;
    display: flex;

    img {
      height: 4rem;
    }
    h5 {
      margin-left: 15px;
      display: flex;
      align-items: center;
    }
  }
`;
const Content = styled.div`
  height: 100%;
  width: 100%;
  padding-top: ${nav_height};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url(${FormBackground});
  background-size: cover;
`;
const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  @media (max-width: 800px) {
    width: 95%;
  }
  max-width: 80rem;
  margin-top: 10px;
  padding: 2rem;
  border: 2px solid #032c4b;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 150px;
  hr {
    margin: 2rem -2rem 2rem -2rem;
    border-top: 1px solid #032c4b;
    width: calc(100% + 4rem);
  }
`;
const Title = styled.h1`
  width: 100%;
  font-size: 3rem;
`;
const GroupLabel = styled.h1`
  text-align: start;
  margin-bottom: 10px;
  color: #032c4b;
  width: 100%;
`;
const HalfSizeDiv = styled.div`
  height: 100%;
  width: 50%;
  padding: 0 20px 0 20px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
const InputGroup = styled.div<{ is_wrong: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: column;
  width: 100%;
  margin-bottom: 1rem;

  > span {
    width: 100%;
    text-align: start;
    font-size: 1.5rem;
    margin-left: 3px;
    color: #032c4b;
    span {
      visibility: ${(props) => (props.is_wrong ? "visible" : "hidden")};
      float: right;
      color: red;
      font-size: 1.2rem;
    }
  }

  > div {
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.375rem 0.75rem;
      height: calc(3rem + 5px);
      width: 60px;
      @media (max-width: 800px) {
        width: 40px;
      }
      min-width: 30px;
      margin-bottom: 0;
      font-size: 2rem;
      color: #fff;
      background-color: #032c4b;
      border: 1px solid #011229;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }
  > input {
    flex: 1 1 auto;
    width: auto;
    height: calc(3rem + 5px);
    padding: 0.375rem 0.75rem;
    font-size: 1.1rem;
    color: #000;
    background-color: #fff;
    border: ${(props) =>
      props.is_wrong ? "1px solid red" : "1px solid #032c4b"};
    border-left: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
`;

const SelectGroup = styled(InputGroup)<{ is_wrong: boolean }>`
  > div {
    width: 100%;
    display: block;
    font-size: 1.1rem;
    > div {
      border: ${(props) =>
        props.is_wrong ? "1px solid red" : "1px solid #032c4b"};
      text-align: start;
      padding: 0.325rem 0;
      //height: calc(3rem + 5px);
    }
    > div:last-child {
      border: none;
      height: initial;
    }
  }
`;
const SearchButton = styled.button`
  background-color: #032c4b;
  border: none;
  border-radius: 30px;
  color: white;
  padding: 5px 32px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  float: right;
  font-size: 1.2rem;
  transition: 200ms;
  svg {
    transform: scale(1.5);
    margin-right: 10px;
    margin-bottom: -2px;
  }
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
  :not(:disabled):hover {
    transform: scale(1.1);
    filter: brightness(1.5);
  }
`;

const CheckboxGroup = styled.div<{ is_wrong: boolean }>`
  overflow: hidden;
  float: left;
  display: flex;
  flex-wrap: wrap;
  align-items: column;
  border: ${(props) =>
    props.is_wrong ? "1px solid red" : "1px solid #032c4b"};
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  justify-content: center;
  > span {
    width: 100%;
    text-align: start;
    font-size: 1.5rem;
    margin-left: 3px;
    color: #032c4b;
    margin-bottom: 10px;
    span {
      float: right;
      color: red;
      font-size: 1.2rem;
    }
  }
  label {
    float: left;
    cursor: pointer;
    span {
      border: 1px solid #032c4b;
      border-radius: 20px;
      background-color: #fff;
      color: #032c4b;
      margin: 1.5px;
      padding: 5px 20px 5px 20px;
      text-align: center;
      display: block;
      font-size: 1.1rem;
      transition: 200ms;
    }
    input {
      position: absolute;
      display: none;
      color: #fff !important;
      :checked + span {
        color: #fff;
        background-color: #032c4b;
        border: none;
      }
    }
  }
`;

const OtherInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > input {
    width: 25rem;
    height: calc(3rem + 5px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    color: #000;
    background-color: #fff;
    border: 1px solid #032c4b;
    border-radius: 0.25rem;
    margin-top: 1.5px;
    margin-left: 1.5px;
  }
`;

const RegisterButton = styled(SearchButton)`
  padding: 10px 32px;
  font-size: 1.2rem;
  margin-left: auto;
  margin-top: 20px;
`;

const CaptchaBox = styled(CheckboxGroup)`
  > div {
    @media (max-width: 800px) {
      width: 25rem;
    }
  }
`;
export {
  Navbar,
  Content,
  Form,
  Title,
  InputGroup,
  SelectGroup,
  GroupLabel,
  HalfSizeDiv,
  SearchButton,
  CheckboxGroup,
  RegisterButton,
  OtherInput,
  CaptchaBox,
};
