import { Empresa } from "../models/Empresa";

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTUwNjc1ODZ9.oLfNLXUAPpiR9h1w5A2w3rrkc-5_yfgppAAZHizTc-8";

const host = "api.brainmarket.com.br";
//Buscar dados do checkbox na API

async function getCheckboxData() {
  const response = await fetch("https://" + host + "/checkboxData", {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return await response.json();
}

//Enviar requisição de criar uma empresa no banco de dados
async function createEmpresa(empresa: Empresa) {
  const response = await fetch("https://" + host + "/adicionarEmpresa", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(empresa),
  });
  return await response.json();
}

export async function getEmpresaInfoCNPJ(cnpj: string) {
  const response = await fetch("https://" + host + "/getCNPJinfo/" + cnpj, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json();
}

export async function verifyCaptcha(captcha_value: string) {
  const response = await fetch("https://" + host + "/validateCaptcha", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({ captcha: captcha_value }),
  });
  return await response.json();
}

export { createEmpresa, getCheckboxData };
