import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { GiConfirmed } from "react-icons/gi";
import { createEmpresa } from "../FetchApi/FetchEmpresa";
import { CgDanger } from "react-icons/cg";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTable,
  SuccessButton,
  DangerButton,
  Popup,
  Check,
} from "./FormModalStyle";
import { Empresa } from "../models/Empresa";
//Controla se o modal será aberto ou não
function FormModal(props: { formData: Empresa; closeModal: () => void }) {
  const [showPopUp, setShowPopUp] = useState({
    show: false,
    style: false,
    message: "",
  });
  const [termos_check, setTermosCheck] = useState(false);
  return (
    <>
      <Modal form_display={Object.keys(props.formData).length > 0}>
        <div>
          <ModalHeader>
            <h1>Os dados inseridos estão corretos?</h1>
            <button onClick={() => props.closeModal()}>
              <AiFillCloseCircle />
            </button>
          </ModalHeader>
          {Object.keys(props.formData).length > 0 ? (
            <>
              <ModalBody>
                <ModalTable>
                  <h2>Informações Básicas</h2>
                  <table>
                    <tbody>
                      <tr>
                        <td>CNPJ</td>
                        <td>{props.formData.cnpj}</td>
                      </tr>
                      <tr>
                        <td>Razão Social</td>
                        <td>{props.formData.razao_social}</td>
                      </tr>
                      <tr>
                        <td>Representante</td>
                        <td>{props.formData.representante}</td>
                      </tr>
                      {/*<tr>
                    <td>Telefone Fixo</td>
                    <td>{props.formData.telefone_fixo}</td>
                  </tr>*/}
                      <tr>
                        <td>Telefone</td>
                        <td>{props.formData.telefone_celular}</td>
                      </tr>
                      <tr>
                        <td>E-mail</td>
                        <td>{props.formData.email}</td>
                      </tr>
                      <tr>
                        <td>UF</td>
                        <td>{props.formData.estado_uf}</td>
                      </tr>
                      <tr>
                        <td>Município</td>
                        <td>{props.formData.municipio}</td>
                      </tr>
                      <tr>
                        <td>Bairro</td>
                        <td>{props.formData.bairro}</td>
                      </tr>
                      <tr>
                        <td>Endereço</td>
                        <td>{props.formData.endereco}</td>
                      </tr>
                      <tr>
                        <td>Site</td>
                        <td>{props.formData.site}</td>
                      </tr>
                    </tbody>
                  </table>
                </ModalTable>
                <ModalTable>
                  <h2>Qualificação do Fornecedor</h2>
                  <table>
                    <tbody>
                      <tr>
                        <td>Tipo de empresa</td>
                        <td>{props.formData.tipo_empresa?.nome}</td>
                      </tr>
                      <tr>
                        <td>Faturamento Anual</td>
                        <td>{props.formData.faturamento?.nome}</td>
                      </tr>
                      <tr>
                        <td>Capital Social</td>
                        <td>{props.formData.capital_social?.nome}</td>
                      </tr>
                      <tr>
                        <td>Certificações</td>
                        <td>
                          {props.formData.certificacoes?.map(
                            (certificacao, index) => {
                              if (
                                index + 1 ===
                                props.formData.certificacoes?.length
                              )
                                return certificacao.nome;
                              else return certificacao.nome + ", ";
                            }
                          ) ?? null}
                        </td>
                      </tr>
                      <tr>
                        <td>Portifólio</td>
                        <td>
                          {props.formData.portifolio?.map(
                            (_portifolio, index) => {
                              if (
                                index + 1 ===
                                props.formData.portifolio?.length
                              )
                                return _portifolio.nome;
                              else return _portifolio.nome + ", ";
                            }
                          ) ?? null}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ModalTable>
                {/* 
            <ModalList>
            <table>
            <tbody>
            <tr>
            <td>Certificações</td>
            </tr>
            {props.formData.certificacoes?.map((certificacao) => {
              return (
                <tr key={certificacao.id}>
                <td>{certificacao.nome}</td>
                </tr>
                );
                  }) ?? null}
                  </tbody>
                  </table>
                  </ModalList>
                  <ModalList>
                  <table>
                  <tbody>
                  <tr>
                  <td>Portifólios de Serviços da Empresa</td>
                  </tr>
                  {props.formData.portifolio?.map((port) => {
                    return (
                      <tr key={port.id}>
                      <td>{port.nome}</td>
                      </tr>
                      );
                    }) ?? null}
                    </tbody>
                    </table>
                    </ModalList>
                  */}
                <Check>
                  <input
                    type="checkbox"
                    onClick={() => setTermosCheck(!termos_check)}
                  />
                  <span>
                    Autorizo a gestão dos dados informados, pelos organizadores
                    da Brainmarket, obedecendo às diretrizes e regras da Lei
                    Geral de Proteção de Dados Pessoais – Lei nº 13.709/2018.
                  </span>
                </Check>
              </ModalBody>
              <hr />
              <ModalFooter>
                <DangerButton onClick={() => props.closeModal()}>
                  <AiFillCloseCircle /> Cancelar
                </DangerButton>
                <SuccessButton
                  disabled={!termos_check}
                  onClick={() => {
                    createEmpresa(props.formData).then((response) => {
                      if (response.message)
                        setShowPopUp({
                          show: true,
                          style: true,
                          message: "Empresa cadastrada com sucesso!",
                        });
                      else
                        setShowPopUp({
                          show: true,
                          style: false,
                          message:
                            "Já existe uma empresa com esse CNPJ cadastrada!",
                        }); //alert("Já existe uma empresa com esse CNPJ cadastrada!");
                    });
                    //location.reload();
                  }}
                >
                  <GiConfirmed /> Estão de acordo!
                </SuccessButton>
              </ModalFooter>
            </>
          ) : null}
        </div>
      </Modal>
      <Popup form_display={showPopUp.show}>
        <div>
          {showPopUp.style ? (
            <GiConfirmed style={{ color: "#28a745" }} />
          ) : (
            <CgDanger style={{ color: "#dc3545" }} />
          )}

          <h1>{showPopUp.message}</h1>
          {showPopUp.style ? (
            <SuccessButton
              onClick={() =>
                (window.location.href = "http://www.brainmarket.com.br/")
              }
            >
              Ok
            </SuccessButton>
          ) : (
            <DangerButton onClick={() => window.location.reload()}>
              Ok
            </DangerButton>
          )}
        </div>
      </Popup>
    </>
  );
}

export default FormModal;
