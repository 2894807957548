import React, { useEffect, useState } from "react";
import LogoBrain from "../assets/Brainmarket-logo.png";
import {
  HiUser,
  HiNewspaper,
  HiPhone,
  HiOutlineGlobeAlt,
} from "react-icons/hi";
import { ImOffice } from "react-icons/im";
import { MdEmail, MdSearch } from "react-icons/md";
import { LuCopyCheck } from "react-icons/lu";
import MaskedInput from "react-text-mask";
//import createNumberMask from "text-mask-addons/dist/createNumberMask";
import ReCAPTCHA from "react-google-recaptcha";

import {
  CaptchaBox,
  CheckboxGroup,
  Content,
  Form,
  GroupLabel,
  HalfSizeDiv,
  InputGroup,
  Navbar,
  OtherInput,
  RegisterButton,
  SearchButton,
  SelectGroup,
  Title,
} from "./EmpresaFormStyle";

//import { getEmpresaInfoCNPJ } from "../FetchApi/FetchCNPJ";
import {
  getCheckboxData,
  getEmpresaInfoCNPJ,
  verifyCaptcha,
} from "../FetchApi/FetchEmpresa";
import FormModal from "./FormModal";
import { Empresa, DatabaseOptions } from "../models/Empresa";
import Select from "react-select";
//Interface para receber os dados que preenchem os checkboxes
interface CheckBoxData {
  portifolio: DatabaseOptions[];
  certificacoes: DatabaseOptions[];
  faturamento: DatabaseOptions[];
  tipo_empresa: DatabaseOptions[];
}

function EmpresaForm() {
  //Armazena o conteúdo do campo CNPJ caso ele seja completamente preenchido
  const [cnpj_field, setCNPJField] = useState("");
  //State para controlar o botão de busca do CNPJ, para ativá-lo, ou desativá-lo dinâmicamente
  const [search_button_disabled, setSearchButtonDisabled] = useState(true);
  //Armazena todos os campos do formulário que foram preenchidos
  const [form_data, setFormData] = useState<Empresa>({});
  //Recebe o conteúdo dos dados dos checkbox do banco e preenche os campos com eles (também usado para inserir novas opções)
  const [checkbox_data, setCheckboxData] = useState<CheckBoxData>();
  //Controla se o botão "Outro" foi ativado ou não
  const [other_check, setOtherCheck] = useState(false);
  //Array que armazena todos os inputs incorretos do formulário
  const [incorrect_field, setIncorrectField] = useState<string[]>([]);
  //Objeto que armazena as informações da empresa que vieram da API
  const [cnpj_api_info, setCNPJApiInfo] = useState<Empresa>({});

  const [portifolio_selected, setPortifolioSelected] = useState<
    DatabaseOptions[]
  >([]);

  const [captcha_check, setCaptchaCheck] = useState(false);

  //Buscar CNPJ na API atraves apos deixar a string apenas com numeros, em seguida retornar valores correspondentes
  const searchCNPJ = () => {
    getEmpresaInfoCNPJ(cnpj_field.replace(/[.\-_/]/g, "")).then((result) => {
      if (result["STATUS"] === "ATIVA") {
        (
          document.getElementById("razao_social_ref") as HTMLInputElement
        ).value = result["RAZAO SOCIAL"];
        if (
          (document.getElementById("email_ref") as HTMLInputElement).value ===
          ""
        )
          (document.getElementById("email_ref") as HTMLInputElement).value =
            result["EMAIL"];
        let endereco: string =
          result["TIPO LOGRADOURO"] +
          " " +
          result["LOGRADOURO"] +
          ", NÚMERO " +
          result["NUMERO"];
        if (result["COMPLEMENTO"] !== "")
          endereco += ", " + result["COMPLEMENTO"];
        setCNPJApiInfo({
          perfil: result["CNAE PRINCIPAL DESCRICAO"],
          endereco: endereco,
          estado_uf: result["UF"],
          municipio: result["MUNICIPIO"],
          bairro: result["BAIRRO"],
          cep: result["CEP"],
        });
      } else {
        setCNPJField("");
      }
      setSearchButtonDisabled(true);
    });
  };
  //Ao clicar no botao de confirmação, os dados de cada input/checkbox sao tratados nesse metodo
  const verifyData = () => {
    const certificacoes: DatabaseOptions[] = [];
    let faturamento: DatabaseOptions = { id: -1, nome: "" };
    let tipo_empresa: DatabaseOptions = { id: -1, nome: "" };
    const capital_social: DatabaseOptions = { id: -1, nome: "" };

    document
      .querySelectorAll<HTMLInputElement>("input[name='tipo_empresa_check']")
      .forEach((checkbox) => {
        if (checkbox.checked)
          tipo_empresa = { id: +checkbox.value, nome: checkbox.placeholder };
      });

    document
      .querySelectorAll<HTMLInputElement>("input[name='faturamento_check']")
      .forEach((checkbox) => {
        if (checkbox.checked)
          faturamento = { id: +checkbox.value, nome: checkbox.placeholder };
      });

    document
      .querySelectorAll<HTMLInputElement>("input[name='certificacao_check']")
      .forEach((checkbox) => {
        if (checkbox.checked)
          certificacoes.push({
            id: +checkbox.value,
            nome: checkbox.placeholder,
          });
      });

    document
      .querySelectorAll<HTMLInputElement>("input[name='capital_social_check']")
      .forEach((checkbox) => {
        if (checkbox.checked) {
          capital_social.id = +checkbox.value;
          capital_social.nome = checkbox.placeholder;
        }
      });
    /*
    document
      .querySelectorAll<HTMLInputElement>("input[name='portifolio_check']")
      .forEach((checkbox) => {
        if (checkbox.checked)
          portifolio.push({ id: +checkbox.value, nome: checkbox.placeholder });
      });*/
    //Verificar campos obrigatorios
    if (checkInputs(faturamento, tipo_empresa, capital_social)) {
      setFormData({
        cnpj: cnpj_field,
        razao_social:
          (document.getElementById("razao_social_ref") as HTMLInputElement)
            .value ?? "",
        email:
          (document.getElementById("email_ref") as HTMLInputElement).value ??
          "",
        telefone_celular:
          (document.getElementById("celular_ref") as HTMLInputElement).value ??
          "",
        //telefone_fixo:
        //  (document.getElementById("fixo_ref") as HTMLInputElement).value ?? "",
        representante:
          (document.getElementById("representante_ref") as HTMLInputElement)
            .value ?? "",
        capital_social: capital_social,
        site:
          (document.getElementById("site_ref") as HTMLInputElement).value ?? "",
        faturamento: faturamento,
        tipo_empresa: tipo_empresa,
        portifolio: portifolio_selected ?? [],
        certificacoes: certificacoes ?? [],
        perfil: cnpj_api_info.perfil,
        endereco: cnpj_api_info.endereco,
        estado_uf: cnpj_api_info.estado_uf,
        municipio: cnpj_api_info.municipio,
        bairro: cnpj_api_info.bairro,
        cep: cnpj_api_info.cep,
      });
    }
  };
  // Método utilizado para devolver quais campos estão incorretos e sinalizá-los para que sejam modificados de acordo
  const checkInputs = (
    faturamento: DatabaseOptions,
    tipo_empresa: DatabaseOptions,
    capital_social: DatabaseOptions
  ) => {
    const wrong_inputs: string[] = [];
    if (cnpj_field === "" || cnpj_field.length < 18)
      wrong_inputs.push("cnpj_ref");
    /* if (
      (document.getElementById("capital_social_ref") as HTMLInputElement)
        ?.value === ""
    )
      wrong_inputs.push("capital_social_ref");
*/
    if (
      !(
        document.getElementById("email_ref") as HTMLInputElement
      )?.value.includes("@") &&
      !(
        (
          document.getElementById("email_ref") as HTMLInputElement
        )?.value.includes(".com") ||
        (
          document.getElementById("email_ref") as HTMLInputElement
        )?.value.includes(".br")
      )
    )
      wrong_inputs.push("email_ref");
    if (
      (
        document.getElementById("celular_ref") as HTMLInputElement
      )?.value.replace(/ /g, "").length < 13
    )
      wrong_inputs.push("celular_ref");
    if (
      (document.getElementById("representante_ref") as HTMLInputElement)
        ?.value === ""
    )
      wrong_inputs.push("representante_ref");
    /*if (
      (document.getElementById("capital_social_ref") as HTMLInputElement)?.value
        .length < 6
    )
      wrong_inputs.push("capital_social_ref");
*/
    if (
      (document.getElementById("razao_social_ref") as HTMLInputElement)
        ?.value === ""
    )
      wrong_inputs.push("razao_social_ref");
    if (capital_social.id < 0) wrong_inputs.push("capital_social_ref");

    if (faturamento.id < 0) wrong_inputs.push("faturamento_ref");
    if (tipo_empresa.id < 0) wrong_inputs.push("tipo_empresa_ref");
    if (portifolio_selected.length < 1) wrong_inputs.push("portifolio_ref");
    if (!captcha_check) wrong_inputs.push("captcha_ref");
    setIncorrectField(wrong_inputs);
    return wrong_inputs.length === 0;
  };
  //Apaga os dados do formulário, usado para controlar o modal
  const closeModal = () => {
    setFormData({});
  };

  const transformPortifolioSelect = () => {
    const portifolio_local_options: { value: number; label: string }[] = [];
    if (checkbox_data !== undefined)
      checkbox_data.portifolio.forEach((portifolio) =>
        portifolio_local_options.push({
          value: portifolio.id,
          label: portifolio.nome,
        })
      );
    return portifolio_local_options;
  };
  //Infla os checkboxes com as opções
  useEffect(() => {
    getCheckboxData().then((data: CheckBoxData) => {
      setCheckboxData(data);
    });
  }, []);

  //Previne que o nome da empresa se mantenha após o input do cnpj ser modificado
  useEffect(() => {
    if (
      cnpj_field === "" &&
      (document.getElementById("razao_social_ref") as HTMLInputElement)
        ?.value !== ""
    )
      (document.getElementById("razao_social_ref") as HTMLInputElement).value =
        "";
  }, [cnpj_field]);

  return (
    <>
      <Navbar>
        <div>
          <img src={LogoBrain} alt="Logo Brainmarket" />
          <h5>Cadastro de Fornecedores</h5>
        </div>
      </Navbar>
      <FormModal formData={form_data} closeModal={closeModal} />

      <Content>
        <Form>
          <Title>Cadastre sua Empresa!</Title>
          <hr />
          <GroupLabel>Informações Gerais</GroupLabel>
          <HalfSizeDiv>
            <InputGroup
              is_wrong={
                incorrect_field.includes("cnpj_ref") || cnpj_field === ""
              }
            >
              <span>
                <b>CNPJ *</b>
                <span id="cnpj_span">*Insira um CNPJ correto</span>
              </span>
              <div>
                <span>
                  <ImOffice />
                </span>
              </div>
              <MaskedInput
                placeholder="CNPJ da sua Empresa (Apenas dígitos)"
                mask={[
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  "/",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]}
                id="cnpj_ref"
                onChange={(value: any) => {
                  if (
                    value.target.value.replace(/[.\-_/]/g, "").length === 14
                  ) {
                    setCNPJField(value.target.value);
                    setSearchButtonDisabled(false);
                  } else {
                    setCNPJField("");
                    setSearchButtonDisabled(true);
                  }
                }}
              />
            </InputGroup>
            <SearchButton
              disabled={search_button_disabled}
              onClick={() => searchCNPJ()}
            >
              <MdSearch />
              Verificar CNPJ
            </SearchButton>
            <InputGroup is_wrong={incorrect_field.includes("cnpj_ref")}>
              <span>
                <b>Representante *</b>
                <span>campo vazio ou incorreto</span>
              </span>
              <div>
                <span>
                  <HiUser />
                </span>
              </div>
              <input
                placeholder="Nome Completo do representante da sua empresa"
                id="representante_ref"
              />
            </InputGroup>
            <InputGroup is_wrong={incorrect_field.includes("celular_ref")}>
              <span>
                <b>Telefone *</b>
                <span>*campo vazio ou incorreto</span>
              </span>
              <div>
                <span>
                  <HiPhone />
                </span>
              </div>
              <MaskedInput
                mask={[
                  "(",
                  /\d/,
                  /\d/,
                  ")",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholderChar=" "
                placeholder="Telefone Celular do representante"
                id="celular_ref"
              />
            </InputGroup>
          </HalfSizeDiv>
          <HalfSizeDiv>
            <InputGroup is_wrong={incorrect_field.includes("razao_social_ref")}>
              <span>
                <b>Razão Social *</b>
                <span>*CNPJ correto deve ser buscado</span>
              </span>
              <div>
                <span>
                  <HiNewspaper />
                </span>
              </div>
              <input
                placeholder="Busque o CNPJ da sua empresa para que seja preenchido"
                disabled
                id="razao_social_ref"
              />
            </InputGroup>
            <InputGroup
              is_wrong={incorrect_field.includes("email_ref")}
              style={{ marginTop: "40px" }}
            >
              <span>
                <b>E-mail *</b>
                <span>*campo vazio ou incorreto</span>
              </span>
              <div>
                <span>
                  <MdEmail />
                </span>
              </div>
              <input
                placeholder="E-mail da empresa ou do Representante"
                id="email_ref"
              />
            </InputGroup>

            <InputGroup is_wrong={false}>
              <span>
                <b>Website</b>
                <span>*campo vazio ou incorreto</span>
              </span>
              <div>
                <span>
                  <HiOutlineGlobeAlt />
                </span>
              </div>
              <input placeholder="Website da empresa" id="site_ref" />
            </InputGroup>
          </HalfSizeDiv>
          <hr />
          <GroupLabel>Qualificação do Fornecedor</GroupLabel>
          <HalfSizeDiv>
            <CheckboxGroup
              is_wrong={incorrect_field.includes("tipo_empresa_ref")}
            >
              <span>
                <b>Tipo de empresa *</b>

                <span>selecione apenas um</span>
              </span>
              {checkbox_data?.tipo_empresa.map((tipo_empresa) => {
                return (
                  <label key={tipo_empresa.id}>
                    <input
                      type="radio"
                      placeholder={tipo_empresa.nome}
                      value={tipo_empresa.id}
                      name="tipo_empresa_check"
                    />
                    <span>{tipo_empresa.nome}</span>
                  </label>
                );
              })}
            </CheckboxGroup>
            <CheckboxGroup
              is_wrong={incorrect_field.includes("faturamento_ref")}
            >
              <span>
                <b>Faturamento anual *</b>

                <span>selecione apenas um</span>
              </span>
              {checkbox_data?.faturamento.map((faturamento) => {
                return (
                  <label key={faturamento.id}>
                    <input
                      type="radio"
                      placeholder={faturamento.nome}
                      value={faturamento.id}
                      name="faturamento_check"
                    />
                    <span>{faturamento.nome}</span>
                  </label>
                );
              })}
            </CheckboxGroup>
          </HalfSizeDiv>
          <HalfSizeDiv>
            <CheckboxGroup
              is_wrong={incorrect_field.includes("capital_social_ref")}
            >
              <span>
                <b>Capital Social *</b>
                <span>selecione apenas um</span>
              </span>

              <label>
                <input
                  type="radio"
                  value={10000}
                  placeholder="Entre R$0,00 e R$10.000,00"
                  name="capital_social_check"
                />
                <span>Entre R$0,00 e R$10.000,00</span>
              </label>
              <label>
                <input
                  type="radio"
                  placeholder="Entre R$10.000,00 e R$100.000,00"
                  value={100000}
                  name="capital_social_check"
                />
                <span>Entre R$10.000,00 e R$100.000,00</span>
              </label>
              <label>
                <input
                  type="radio"
                  placeholder="Acima de R$100.000,00"
                  value={100001}
                  name="capital_social_check"
                />
                <span>Acima de R$100.000,00</span>
              </label>
            </CheckboxGroup>
            {/*

            <InputGroup
              is_wrong={incorrect_field.includes("capital_social_ref")}
            >
              <span>
                <b>Capital Social</b>
                <span>*campo vazio ou incorreto</span>
              </span>
              <div>
                <span>
                  <HiCash />
                </span>
              </div>
              <MaskedInput
                mask={createNumberMask({
                  prefix: "R$",
                  thousandsSeparatorSymbol: ".",
                  suffix: ",00",
                  integerLimit: 9,
                })}
                placeholder="Capital Social da Empresa"
                id="capital_social_ref"
              />
            </InputGroup>
                */}
            <CheckboxGroup is_wrong={false}>
              <span>
                <b>Certificações</b>
                <span>selecione um ou vários</span>
              </span>
              {checkbox_data?.certificacoes.map((certificacao) => {
                return (
                  <label key={certificacao.id}>
                    <input
                      type="checkbox"
                      placeholder={certificacao.nome}
                      value={certificacao.id}
                      name="certificacao_check"
                    />
                    <span>{certificacao.nome}</span>
                  </label>
                );
              })}
              <label>
                <input
                  type="checkbox"
                  value="-2"
                  onClick={() => setOtherCheck(!other_check)}
                />
                <span>Outro</span>
              </label>
              <OtherInput>
                {other_check ? (
                  <input
                    placeholder="Insira sua certificação e aperte Enter para adicionar"
                    id="outro_certificacao"
                    onKeyUp={(event: any) => {
                      if (event.key === "Enter" && event.target.value !== "") {
                        const local_checkbox_data = { ...checkbox_data };
                        local_checkbox_data.certificacoes?.push({
                          id: -1,
                          nome: event.target.value,
                        });
                        setCheckboxData(local_checkbox_data as CheckBoxData);
                        event.target.value = "";
                      }
                    }}
                  />
                ) : null}
              </OtherInput>
            </CheckboxGroup>
            {
              <SelectGroup
                is_wrong={incorrect_field.includes("portifolio_ref")}
              >
                <span>
                  <b>Portifólio de Serviços *</b>
                  <span>*selecione pelo menos um</span>
                </span>
                <Select
                  isMulti
                  name="portifolio_check"
                  placeholder="Portifólio de serviços da sua empresa"
                  options={transformPortifolioSelect()}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    if (e.length > 0) {
                      const local_portifolio: DatabaseOptions[] = [];
                      e.forEach((portifolio) => {
                        local_portifolio.push({
                          id: portifolio.value,
                          nome: portifolio.label,
                        });
                      });
                      setPortifolioSelected(local_portifolio);
                    } else setPortifolioSelected([]);
                  }}
                />
              </SelectGroup>
            }
            <CaptchaBox is_wrong={incorrect_field.includes("captcha_ref")}>
              <span>
                <b>Verificação de Segurança *</b>
              </span>
              <ReCAPTCHA
                sitekey="6LewXVcnAAAAAFX0rGsAg0ZvBcbGz_Sn65pWekjK"
                onChange={(value) =>
                  verifyCaptcha(value as string).then(
                    (result: { success: boolean }) =>
                      setCaptchaCheck(result.success)
                  )
                }
              />
            </CaptchaBox>
          </HalfSizeDiv>

          {incorrect_field.length > 0 ? (
            <span style={{ float: "right", color: "red", fontSize: "1.2rem" }}>
              *Existem campos incorretos
            </span>
          ) : null}

          <RegisterButton onClick={() => verifyData()}>
            <LuCopyCheck /> Registrar Empresa
          </RegisterButton>
        </Form>
      </Content>
    </>
  );
}

export default EmpresaForm;
