import styled from "styled-components";

const Modal = styled.div<{ form_display: boolean }>`
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: ${(props) => (props.form_display ? "100%" : "0%")};
  background-color: rgba(0, 0, 0, 0.4);
  visibility: ${(props) => (props.form_display ? "visible" : "hidden")};
  transition: 200ms;
  overscroll-behavior: contain;
  > div {
    background-color: #fefefe;
    margin: 1% auto;
    @media (max-width: 800px) {
      margin: 1% 5%;
    }
    border: 1px solid #011229;
    border-radius: 20px;
    max-width: 800px;
    height: 95%;
    tr {
      border-top: 1px solid #032c4b;
    }
  }
`;
const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 20px;
  border-radius: 18px 18px 0 0;
  background-color: #032c4b;
  color: #fff;
  font-weight: bolder;
  > h1 {
    margin: 0;
    @media (max-width: 500px) {
      width: 80%;
    }
  }
  > button {
    background-color: #fff;
    color: #032c4b;
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    transition: 250ms;
    cursor: pointer;
    padding: 0;
    svg {
      height: 100%;
      width: 100%;
    }
    :hover {
      transform: scale(1.1);
      filter: brightness(0.8);
    }
  }
`;
const ModalBody = styled.div`
  padding: 20px;
  height: calc(90% - 60px);
  overflow-y: auto;
`;
const ModalFooter = styled.div`
  float: right;
  margin-top: 5px;
  @media (max-width: 800px) {
    width: 100%;
    padding: 5px;
  }
`;

const ModalTable = styled.div`
  max-width: 700px;
  margin: 0 auto;
  font-size: 1.2rem;
  > h2 {
    margin-bottom: 20px;
    text-align: start;
  }
  > table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    td {
      padding: 10px;
      border: 1px solid #011229;
      text-align: start;
      @media (max-width: 400px) {
        padding: 5px;
      }
      :first-child {
        background-color: #032c4b;
        color: #fff;
        width: 180px;
        @media (max-width: 400px) {
          width: 140px;
        }
        font-weight: bold;
      }
    }
  }
`;

const ModalList = styled.div`
  max-width: 500px;
  margin: 0 auto;
  font-size: 1.2rem;
  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    tbody {
      > tr {
        td {
          width: 100%;
          padding: 10px;
          border: 1px solid #011229;
          text-align: start;
        }
        :first-child td {
          background-color: #032c4b;
          color: #fff;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
`;

const Button = styled.button`
  background-color: #032c4b;
  border: none;
  border-radius: 30px;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  transition: 200ms;
  svg {
    transform: scale(1.5);
    margin-right: 10px;
    margin-bottom: -2px;
  }
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
  :not(:disabled):hover {
    transform: scale(1.1);
    filter: brightness(1.2);
  }
`;
const SuccessButton = styled(Button)`
  color: #fff;
  background-color: #28a745;
  border: 2px solid #178230;
`;
const DangerButton = styled(Button)`
  color: #fff;
  background-color: #dc3545;
  border: 2px solid #b51f2d;
`;

const Popup = styled(Modal)<{ form_display: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    border: none;
    width: 500px;
    height: 300px;
    @media (max-width: 600px) {
      width: 400px;
      height: 250px;
    }
    svg {
      height: 8rem;
      width: 8rem;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    button {
      margin-right: 0;
      margin-top: 20px;
    }
  }
`;
const Check = styled.div`
  font-size: 1.3rem;
  display: flex;
  flex-direction: start;
  align-items: center;
  padding: 0 10px;
  input {
    transform: scale(1.5);
    margin-right: 10px;
    cursor: pointer;
  }
`;
export {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalTable,
  ModalList,
  SuccessButton,
  DangerButton,
  Popup,
  Check,
};
